<template>
  <section class="buttons-preview media-preview">
    <vs-card class="cardx mb-0">
      <div class="name text-black">{{ text }}</div>
      <div class="buttons">
        <div
          v-for="(button, index) in buttons"
          v-if="button && button.text"
          :key="index"
          class="option"
        >
          <span>{{ button.text }}</span>
        </div>
      </div>
    </vs-card>
  </section>
</template>

<script>
export default {
  name: 'PreviewButtons',
  props: {
    msg: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    text() {
      return this.msg.responseText
    },
    buttons() {
      return this.msg.responseOptions
    }
  },
  methods: {},
  watch: {}
}
</script>

<style lang="scss">
.buttons-preview {
  font-size: 1rem;
  .con-vs-card {
    background: #f0f0f0;
    .vs-card--content {
      border: none;
      height: fit-content;
      padding: 15px 15px 15px 15px;
      white-space: pre-wrap;
      word-break: break-word;
      word-wrap: break-word;
      .name {
        overflow: hidden;
        overflow-wrap: break-word;
        border-radius: 20px 20px 0 0;
        border: none;
        padding: 5px 15px 0 15px;
        background-color: #f0f0f0;
        white-space: pre-wrap;
        word-break: break-word;
        word-wrap: break-word;
      }
      .option {
        margin: 10px auto;
        justify-content: center;
        align-items: center;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 5px;
        line-height: 1rem;
        background-color: #fff;
        color: #000;
        span {
          color: black;
        }
      }
    }
  }
}
</style>
